export default function vueHelper(Vue) {
  Vue.config.errorHandler = function (err, vm, info) {
    // 由于error会被owl默认识别到，所以只在dev环境下开启error输出，线上修改为warn
    // 否则logan将会出现两条错误信息
    if(window.Owl.cfgManager.get('devMode') === true){
      console.error(err)
    }else {
      console.warn(err)
    }
    window.owl('addError', err);
  }
}
