
function filtersInput(domList) {
  const arr = []
  for (let index = 0; index < domList.length; index++) {
    const element = domList[index];
    if (element.nodeName.toLowerCase() === 'input') {
      arr.push(element)
    }
  }
  return arr
}

function inputCallback(type, event) {
  const target = event.target
  const classList = Array.from(target.classList)
  window.Logan.info(`${type}-input.${classList.join('.')}值:${target.value}`)
}


/**
 * 监听input框输入变化
 */
export default function inputBehavior(callback) {
  // 有可能出现不兼容的情况,直接返回就好
  if (!MutationObserver) return

  // 初始化的时候，监听input输入框,比如vue挂载时
  setTimeout(() => {
    const inputList = Array.from(document.querySelectorAll('input'))
    inputList.forEach((node) => {
      node.addEventListener('blur', inputCallback.bind(null, 'blur'))
      node.addEventListener('focus', inputCallback.bind(null, 'focus'))
    })
  }, 0);


  var observer = new MutationObserver(function (mutationsList) {
    const domListArray = mutationsList.filter(row => row.type === 'childList')
    for (const domMuation of domListArray) {
      let { addedNodes = [], removedNodes = [] } = domMuation
      addedNodes = filtersInput(addedNodes)
      removedNodes = filtersInput(removedNodes)
      addedNodes.forEach(node => {
        node.addEventListener('blur', inputCallback.bind(null, 'blur'))
        node.addEventListener('focus', inputCallback.bind(null, 'focus'))
      })

      // removedNodes.forEach(node => {
      //   node.removeEventListener('blur', inputCallback)
      // })
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
}
