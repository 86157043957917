import merge from 'deepmerge'

import UserBehavior from "./userBehavior"

class Metrics {
  static init(config) {
    const { project, devMode } = config;

    if (!project) {
      throw new Error('请填写以下必要参数：‘project’！')
    }

    // owl配置：https://km.sankuai.com/page/192155549
    const defaultConfig = {
      /** 监控内容配置 */
      autoCatch: {
        page: true,
        ajax: false,
        fetch: false,
        resource: true,
        js: true,
        console: true,
        pv: true,
      },

      /** 基本采集策略 */
      // 性能指标（page）相关
      page: {
        sample: 1,
        auto: true,
        delay: 1000,
        // 首屏时间 FST 相关
        sensoryIndex: true,
        disableSensoryImageIndex: true,
        interactToStopObserver: true,
        mutaStopTime: 3000,
        logFirstScreen: !devMode,
        // 首屏慢访问分析
        fstPerfAnalysis: true,
        fstPerfSample: 0.5,
        logSlowView: true,
      },

      // 请求（resource / ajax / image）相关
      resource: {
        sampleApi: 1,
        delay: 1000,
        ignoreMTSIForbidRequest: true,
        enableStatusCheck: true,
      },
      ajax: {
        flag: true,
        duration: 5000,
        invalid: true,
      },
      image: {
        flag: true,
        fileSize: 400,
        duration: 5000,
      },

      // 异常（error）相关
      error: {
        sample: 1,
        combo: true,
        delay: 1000,
        maxNum: 100,
        maxTime: 60 * 1000,
        formatUnhandledRejection: true,
      },

      // 自定义指标（metric）相关
      // metric: {
      //   sample: 1,
      //   combo: true,
      //   delay: 1500,
      // },

      /** 采集过滤策略 */
      // resourceReg:
      //   /(.51ping|.dianping|.dpfile|.meituan|.sankuai|.kuxun|.maoyan|.neixin|.mobike|.dper.com)/,
      // ignoreList: {
      //   ajax: ["https?://report.meituan.com/"],
      //   resource: ["https?://hls.dianping.com/", "https?://frep.meituan.net/"],
      //   js: ["Illegal invocation"],
      // },
      // noScriptError: true,

      /** 上报拦截策略 */
      // onErrorPush(instance) {
      //   const category = instance.sec_category;
      //   const keywords = ["onPageShow", "hbnb", "mtnb"];
      //   const regex = new RegExp(keywords.join("|"), "i");
      //   if (regex.test(category)) {
      //     return undefined;
      //   }
      //   return instance;
      // },

      /** 功能配置项 */
      // SPA 相关
      // SPA: {
      //   autoPV: true,
      //   getFST: true,
      // },
      // 请求日志全链路
      enableLogTrace: true,

      // Logan 相关：https://km.sankuai.com/page/354806436
      logan: {
        enable: true,
        // 由于logan上报会自动生成一条日志，上报频率过快会让日志出现大量无用内容，所以减少下频率
        loganReportDelay: 10000, // 上报延时
        config: {
          devMode,
        },
      },
      // 用户行为日志上报
      behavior: {
        autoCatch: true,
        enableRaptor: true,
      },
    };

    const opts = merge(defaultConfig, config);
    console.log(JSON.stringify(opts))
    window.owl("start", opts);
    if (opts.behavior) {
      const userBehavior = new UserBehavior(opts);
      // 依赖logan模块，必须启动logan
      if (opts.behavior.autoCatch && opts.logan.enable) {
        userBehavior.startAutoCatch(opts);
      }
    }
  }
}

export default Metrics;
