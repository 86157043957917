// const is = require('is-type-of');
import is from './is'
import { ENU_LEVEL } from '../constants'
import inputBehavior from './inputBehavior'

class UserBehavior {
  constructor(opts) {
    this.options = {
      ...opts,
    };

    this.reportTimeout = null

    const { behavior } = this.options;

    if (behavior.enableRaptor) {
      this.raptorBehaviorInstance = window.owl(
        "createInstance",
        this.getBehaviorConfig(opts)
      );
    }

    // 覆写Logan的原生方法
    // https://km.sankuai.com/page/354806436
    if (window.Logan) {
      const parseArgs = (args) => {
        if (is.string(args)) {
          return args;
        } else if (is.object(args)) {
          return JSON.stringify(args);
        } else if (is.array(args)) {
          return args
            .map((d) => {
              if (is.string(d)) {
                return d;
              } else if (is.object(d)) {
                return JSON.stringify(d);
              }
              return null;
            })
            .filter((d) => !!d)
            .join(". ");
        }

        return null;
      };

      // 由于babel的原因，不能直接覆写Logan.log方法
      const _Logan = window.Logan
      const Logan = {
        ..._Logan
      }
      const { log, info, warn, error } = Logan;

      Logan.log = (logString, logType, logLevel, tags) => {
        log.call(_Logan, logString, logType, logLevel, tags);
        this.loganReport();

        this.addBehaviorLog(logString, ENU_LEVEL.INFO);
      };

      Logan.info = (args) => {
        info.call(_Logan, args);
        this.loganReport();

        this.addBehaviorLog(parseArgs(args), ENU_LEVEL.INFO);
      };

      Logan.warn = (args) => {
        warn.call(_Logan, args);
        this.loganReport();

        this.addBehaviorLog(parseArgs(args), ENU_LEVEL.WARN);
      };

      Logan.error = (args) => {
        error.call(_Logan, args);
        this.loganReport();

        this.addBehaviorLog(parseArgs(args), ENU_LEVEL.ERROR);
      };
      window.Logan = Logan
    }
  }

  /**
   * 启动用户行为跟踪
   * @param {object} config 配置选项
   * @param {string} config.project 需要采集行为数据的项目
   * @param {string} [config.traceid] 可选，会话中行为跟踪id
   * @param {boolean} [config.autoCatch] 可选，是否自动采集ui操作记录。默认为false
   * @param {boolean} [config.pageUrl] 可选，行为采集的页面地址，默认：location.href
   * @param {boolean} [config.devMode] 可选，是否开发模式，开发和测试环境请设置为true。默认为false
   */
  startAutoCatch() {
    // log cookie
    window.Logan.info(`document.cookie: ${window.document.cookie}`);
    // log referer
    window.Logan.info(`document.referer: ${window.document.referer}`);
    // log ua
    window.Logan.info(`navigator.userAgent: ${window.navigator.userAgent}`);

    const { getElementInfo } = this;
    let clickTimer;

    const eventHandler = function (eventType, delay) {
      const createRecord = (target, evt) => {
        let message = "";
        if (target && target.nodeType === 1) {
          message = getElementInfo(target);
        }
        const xpath = evt.path || (evt.composedPath && evt.composedPath());
        if (xpath) {
          const paths = [];
          for (let i = 1, len = xpath.length; i < len; i++) {
            const el = xpath[i];
            if (el.tagName === "BODY") {
              break;
            }
            paths.push(getElementInfo(el, true));
          }
          message = `${paths.reverse().join(">")}>${message}`;
        }
        const behavior = {
          topic: `ui.${eventType}`,
          content: message,
        };
        return behavior;
      };

      return function handler(evt) {
        if (evt && evt !== null) {
          let target;

          try {
            target = evt.target;
          } catch (err) {
            target = "<unknown>";
          }

          if (target.length !== 0) {
            if (eventType === "click") {
              // eslint-disable-next-line no-unused-expressions
              clickTimer && clearTimeout(clickTimer);
              if (delay) {
                clickTimer = window.setTimeout(() => {
                  window.Logan.info(createRecord(target, evt));
                }, 0);
              } else {
                window.Logan.info(createRecord(target, evt));
              }
            }
          }
        }
      };
    };

    if (document && document.referrer && document.location) {
      const fromUrl = document.referrer;
      const url = document.location.href;
      if (fromUrl !== "") {
        const record = {
          topic: "navigation",
          content: JSON.stringify({
            from: fromUrl,
            to: url,
          }),
        };

        window.Logan.info(record);
      }
    }

    window.document.addEventListener(
      "click",
      eventHandler("click", true),
      false
    );


    // 开启input框监听
    inputBehavior()
  }

  loganReport() {
    if (this.reportTimeout) {
      clearTimeout(this.reportTimeout);
    }

    this.reportTimeout = setTimeout(() => {
      window.Logan.report();
    }, this.options.logan.loganReportDelay);
  }

  getElementInfo(el, sample = false) {
    if (!el || !el.tagName) return "";
    const queue = [];
    queue.push(el.tagName.toLowerCase());
    if (el.id) {
      queue.push(`#${el.id}`);
    }
    const clsName = el.className;
    if (!!clsName && is.string(clsName)) {
      const clsList = clsName.split(/\s+/);
      for (let o = 0, len = clsList.length; o < len; o++) {
        queue.push(`.${clsList[o]}`);
      }
    }
    if (!sample) {
      const img = window
        .getComputedStyle(el)
        .getPropertyValue("background-image");
      if (img && img !== "none") {
        queue.push(`[bg=${img}]`);
      }
      const attrs = ["type", "src", "href", "name", "title", "alt"];
      for (let o = 0, len = attrs.length; o < len; o++) {
        const attr = attrs[o];
        const val = el.getAttribute(attr);
        if (val) {
          queue.push(`[${attr}='${val}']`);
        }
      }
      const innerText = el.innerText;
      if (innerText) {
        queue.push(`$${innerText}`);
      }
    }
    return queue.join("");
  }

  // 解析com.sankuai.cap.fe.behavior所需config
  getBehaviorConfig({ pageUrl, devMode }) {
    return {
      project: "com.sankuai.cap.fe.behavior",
      pageUrl: pageUrl || window.location.href,
      devMode,
      // 是否自动捕获和上报JS、ajax以及资源错误的配置项
      autoCatch: {
        page: false,
        ajax: false,
        fetch: false,
        resource: false,
        js: false,
        console: false,
        pv: false,
      },
      // 错误日志上报
      error: {
        sample: 1,
        combo: true,
        delay: 1000,
      },
    };
  }

  /**
   * 向Raptor增加行为日志
   * @param {object|string} record 行为记录
   * @param {string} record.topic 行为记录的简介、主题
   * @param {string} record.content 行为记录的详情
   * @param {string} [level='debug'] 行为记录层级。包括：debug、info、warn、error。默认：debug
   */
  addBehaviorLog(record, level = ENU_LEVEL.DEBUG) {
    if (!record || !this.raptorBehaviorInstance) return;

    let content;
    if (typeof record === "string") {
      content = {
        traceid: this.options.traceid,
        topic: "",
        content: record,
        createtime: Date.now(),
      };
    } else {
      content = Object.assign(
        {
          traceid: this.options.traceid,
          topic: "",
          content: "",
          createtime: Date.now(),
        },
        record
      );
    }

    this.raptorBehaviorInstance.addError(
      {
        name: `${this.options.project}`,
        msg: JSON.stringify(content, null, 2),
      },
      {
        level,
        tags: {
          traceid: content.traceid,
          project: this.options.project,
          topic: content.topic, // 其他自定义信息
        },
      }
    );
  }
}

export default UserBehavior;
