/**
 * 对is-type-of的polyfill
 * 因为会导致包太大
 */
export default {
  string(arg) {
    return typeof arg === 'string';
  },
  object(arg) {
    return typeof arg === 'object' && arg !== null;
  },
  array(arg) {
    Array.isArray(arg);
  }
}
