/**
 * 兼容旧版规范
 * CAP错误上报规范
 * 挂载至OWL上
 */


function logError(msg) {
  console.error(`reportError:${msg}`)
}
const defaultReportLevel = 'p3'


function message(error) {
  if (error instanceof Error) {
    return error.message
  }
  if (typeof error !== 'object') {
    return error
  }
  return JSON.stringify(error)
}

export default () => {
  const Owl = window.Owl
  // https://km.sankuai.com/page/436246119
  function reportError({ type = 'userAction', name = '', error, level = defaultReportLevel, errorLevel = 'error' } = {}, tags) {
    if (!name) {
      logError('请传入name字段,简单描述该错误类型')
      return
    }
    if (!error || !error instanceof Error) {
      logError('请传入error错误信息,必须是Error对象')
      return
    }
    // 防止一个错误被多次上报
    error._resolve = true;
    Owl.addError({
      name: `${type}-${name}.${level}`,
      msg: error.stack || error.message || error
    }, {
      combo: true,
      category: 'jsError',
      level: errorLevel,
      tags: {
        message: message(error),
        ...tags,
      }
    })
  }

  Owl.reportError = reportError
  Owl.reportUserAction = function (config = {}, tags) {
    config.type = 'userAction'
    Owl.reportError(config, tags)
  }
  Owl.reportApiData = function (config = {}, tags) {
    config.type = 'apiData'
    Owl.reportError(config, tags)
  }
  Owl.reportApiFetch = function (config = {}, tags) {
    config.type = 'apiFetch'
    Owl.reportError(config, tags)
  }
  // 判断错误是否被上报过
  Owl.errorResolve = function (error) {
    return !!error._resolve
  }
}
