/**
 * axios请求入参和出参
 * 抓取并上报值logan
 */

/**
 * 仿照owl打印api日志
 * 比owl更详细
 * @param {*} url
 * @param {*} method
 * @param {*} duration
 * @param {*} traceid
 * @param {*} params
 * @param {*} query
 * @param {*} response
 */
function reportLogan(url, method, duration, traceid, params, query, response, logLevel = 'info',) {
  let logInfo = {
    url,
    method,
    duration,
    traceid,
    timestamp: +new Date(),
    params, query, response
  }
  let logTag = 'ajax';
  if (logLevel === 'error') {
    logTag = 'ajaxError'
  }
  window.Logan.log('[Ajax]:' + JSON.stringify(logInfo), 'owl', logLevel, [logTag]);
}


/**
 * timeout boolean 捕获网络超时错误,默认true
 * @param {*} axios
 * @param {*} options
 */
export default function axiosPlugin(axios, options = {}) {
  const { checkResponseDataError, } = options

  axios.interceptors.request.use(function (request) {
    request.startTime = Date.now()
    return request
  })

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    const { config, data: responseData, headers } = response;
    const { url, data, method, params, startTime } = config;
    let statusCode = 0;
    if (checkResponseDataError && typeof checkResponseDataError === 'function' && checkResponseDataError(response)) {
      statusCode = 1;
    }
    const duration = Date.now() - startTime
    const content = `method:${method},\ndata:${JSON.stringify(data)},\nparams:${JSON.stringify(params)}\ntraceId:${headers['m-traceid']}\nresponseData:${JSON.stringify(responseData)}`

    // 往logan里写一个
    reportLogan(url, method, duration, headers['m-traceid'], data, params, responseData)

    window.owl('addApi', {
      name: url,
      networkCode: 200,
      statusCode, // 这里写死为1，方便raptor配置统一的错误报警
      responseTime: duration,
      content
    });

    return response;
  }, function (error) {
    const { response, } = error;
    if (!response) {
      return Promise.reject(error);
    }
    const { status, config,headers } = response;
    const { url, data, method, params, startTime } = config;
    const duration = Date.now() - startTime

    if (error.code === 'ECONNABORTED') {
      // 网络不通畅错误不做任何处理
    } else {
      // 往logan里写一个
      reportLogan(url, method, duration, headers['m-traceid'], data, params, null, 'error')

      window.owl('addApi', {
        name: url,
        networkCode: status,
        content: `method:${method},data:${JSON.stringify(data)},params:${JSON.stringify(params)}`,
        statusCode: 0
      });
    }

    return Promise.reject(error);
  });
}
